import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeaderTop from "../components/HeaderTop"
import HeaderColor from "../components/HeaderColor"
import Footer from "../components/Footer"
import CallToAction from "../components/CallToAction"
import options from "../../config/options"
import { Row, Col, Empty } from "antd"
import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons"
import styled from "@emotion/styled"

const RowEmptyWrapper = styled(Row)`
  margin: 120px 0;
`
const QueryWrapper = styled.div`
  margin: 80px 0;
`
const CardWrapper = styled.div`
  background: #f5f5f5;
  height: 100%;
  min-height: 100%;
  .contentWrapper {
    padding: 25px;
    h3 {
      font-family: "Prompt", sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #323232;
      margin-bottom: 20px;
    }
    p {
      font-family: "Prompt", sans-serif;
      font-weight: 300;
      font-size: 16px;
      color: #848383;
      margin-bottom: 0;
      span {
        margin-right: 10px;
      }
    }
  }
`
const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`

const Blog = ({ data, pageContext }) => {
  const { defaultTitle } = data.site.siteMetadata
  const posts = data.allContentfulBlogPost.edges
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/blog/" : "/blog/" + (currentPage - 1).toString()
  const nextPage = "/blog/" + (currentPage + 1).toString()

  return (
    <Layout>
      <SEO
        title="Blog - AEC SOLAR CELL"
        description="รีวิวใช้งานจริง ปั้มน้ำโซล่าเซลล์"
      />
      <HeaderTop text={options.headerTop} />
      <HeaderColor />
      {data ? (
        <QueryWrapper>
          <div className="gb-container">
            <Row gutter={[16, 32]}>
              {posts.map((post, index) => {
                return (
                  <Col key={index} xs={24} sm={12} lg={8}>
                    <CardWrapper>
                      <div className="featuredWrapper">
                        <Link to={`/blog/` + post.node.slug}>
                          <Img
                            loading="eager"
                            fadeIn={false}
                            fluid={post.node.featured.fluid}
                          />
                        </Link>
                      </div>
                      <div className="contentWrapper">
                        <Link to={`/blog/` + post.node.slug}>
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: post.node.title + " | " + defaultTitle,
                            }}
                          ></h3>
                        </Link>
                        <p>
                          <CalendarOutlined style={{ color: "#EB3658" }} />
                          {post.node.createdAt}
                        </p>
                      </div>
                    </CardWrapper>
                  </Col>
                )
              })}
            </Row>
            {numPages > 1 && (
              <Row>
                <Col span={24}>
                  <PaginateWrapper>
                    <ul className="ant-pagination">
                      {isFirst ? (
                        <li
                          key="prev"
                          className="ant-pagination-prev ant-pagination-disabled"
                        >
                          <button
                            className="ant-pagination-item-link"
                            type="button"
                            tabIndex="-1"
                            disabled
                          >
                            <LeftOutlined />
                          </button>
                        </li>
                      ) : (
                        <li key="prev" className="ant-pagination-item">
                          <Link to={prevPage} rel="prev">
                            <LeftOutlined />
                          </Link>
                        </li>
                      )}
                      {Array.from({ length: numPages }, (_, i) => (
                        <li
                          key={`pagination-number${i + 1}`}
                          className={
                            i + 1 === currentPage
                              ? "ant-pagination-item ant-pagination-item-active"
                              : "ant-pagination-item"
                          }
                        >
                          <Link
                            to={`/blog/${i === 0 ? "" : i + 1}`}
                            className={i + 1 === currentPage ? "active" : ""}
                          >
                            {i + 1}
                          </Link>
                        </li>
                      ))}
                      {isLast ? (
                        <li
                          key="next"
                          className="ant-pagination-next ant-pagination-disabled"
                        >
                          <button
                            className="ant-pagination-item-link"
                            type="button"
                            tabIndex="-1"
                            disabled
                          >
                            <RightOutlined />
                          </button>
                        </li>
                      ) : (
                        <li key="next" className="ant-pagination-item">
                          <Link to={nextPage} rel="next">
                            <RightOutlined />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </PaginateWrapper>
                </Col>
              </Row>
            )}
          </div>
        </QueryWrapper>
      ) : (
        <RowEmptyWrapper>
          <Col span={24}>
            <Empty />
          </Col>
        </RowEmptyWrapper>
      )}
      <Footer />
      <CallToAction />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query blogMainQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          slug
          title
          createdAt(formatString: "MMMM DD, YYYY")
          featured {
            fluid(maxWidth: 580) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        defaultTitle
      }
    }
  }
`
